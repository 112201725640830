.Skills{
  padding:5em 3em;
  @include themed() {
    background: t(bg);
    color: t(text);
  }

  .Skills-container{
    margin: 0 auto;
    padding:0 20%;
    display: flex;
    justify-content: space-around;
    gap:.5em;
    flex-wrap: wrap;
  }
}


@media screen and (max-width: 800px){

  .Skills{
    padding:none;

    .Skills-container{
      gap: 0;
      padding:2em;
    }

  }

}

@media screen and (max-width: 450px){

  .Skills{
    padding:none;

    .Skills-container{
      gap: 0;
      padding:0;
    }

  }

}



