.About-container{
  display: grid;
  place-items: center;

  .About-text{
    max-width: 60%;
    text-align: left;
    padding: 1.5em;
  }
  .About-header1{
    font-size: 2.5rem;
  }
  .About-header2{
    font-size: 1.5rem;
    font-weight: bold;
  }

  .About-footer{
    width:20em;
    display:flex;
    justify-content: space-around;
    align-items: center;
  }
}


@media screen and (max-width: 450px){
  .About-header1{
      font-size: 1.5rem;
  }
  .About-text{
      max-width: 95%;
      font-size: smaller;
  }
}