.Skill{

    @include themed(){
      box-shadow: 0 9px 12px 8px t(shadow)
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    padding:1.5em;
    border-radius: 2em;
    margin:1em;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}

.Skill:hover{
    transform: translateY(-0.5em);
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
    @include themed(){
      box-shadow: 0 6px 12.5px 12.5px t(shadow)
    }

}

.Skill-text{
    font-size: 1.25em;
}

.Skill-icon{
    height:6em;
    width: 6em;
}

@media  (max-width: 1000px) {
    .Skill{
        padding:1.25em;
        transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
    }
    .Skill-text{
        font-size: 1.25em;
    }
}


@media (max-width: 600px){
    .Skill{
        padding-top: 1em;
        margin:.75em .25em;
        transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
    }
    .Skill-text{
        font-size: .8em;
    }
    .Skill-icon{
        height:4em;
        width: 4em;
    }
}
