.Navbar{
  @include themed(){
    background: t(bg)
  }
  height:5em;
  width:100%;
}
.Navbar-container{
  padding:0 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Navbar-link-container{
  width: 30%;
  max-width: 20em;
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
}
.Navbar-link{
  // color: var(--xanadu) !important;
  @include themed(){
    color: t(text-secondary)
  }
  font-size: larger;
  font-weight: bold;
  margin:0 .5em;
}
#top-initial{
  font-weight: bold;
  font-size: larger;
}
.Navbar-img{
  height:1.5em;
  width: 1.5em;
}

@media screen and (max-width: 1000px){
  #top-initial{
      display:none !important;
  }
  .Navbar-container{
      display: grid;
      justify-content: center;
      padding: 0;
  }
  .Navbar-link-container{
      width: 20em;
  }
}
