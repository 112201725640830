.Project-card{
  padding:2em;
  border-radius: 2em;
  width: 300px;
  height: 550px;
  margin:1em;
  @include themed(){
    box-shadow: 0 9px 12px 8px t(shadow);
    color: t(text);
  }
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}

.Project-card:hover{
  transform: translateY(-0.5em);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
  @include themed(){
    box-shadow: 0 9px 12.5px 12.5px t(shadow)

  }
}

.Project-card-title{
  font-size: 1em;
  margin-bottom: 0;
}
.Project-card-img{
  border-radius: 2em;
  max-width: 250px;
  max-height: 250px;
  height: 250px;
}
.Project-card-text{
  text-align: left;
}

.Project-tag-container{
  margin-top: 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.Project-tag{
  font-size: .8em;
  margin:.25em 0;
  padding: .25em 0.5em;
  border-radius: 1em;
  border:.5px solid $xanadu;
  transition: ease-in-out .4s;
}

.Project-tag:hover{
  background-color: $princeton-orange;
  color:$mint-cream;
  transition: ease-in-out .4s;
  transform: translateY(-5px);
}

.Project-card-links{
  padding:0 30%;
  display: flex;
  justify-content: space-around;

}