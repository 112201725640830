.App{
  text-align: center;
  transition: 0.5s ease;


  @include themed(){
    background: t(bg);
    color: t(text);
  }

}


